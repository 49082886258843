import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";

// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";


//Styles
import "./NuevoAsesor.scss"

//Random id
import { v4 as uuid } from "uuid";


export default function ({ }) {
  //importamos Stein para la base de datos

  const MiembrosDB = new SteinStore(process.env.REACT_APP_MIEMBROS_API);

  //campos
  const [name, setName] = useState("")
  const [cua, setCua] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [date, setDate] = useState("")
  const [adressCalle, setAdressCalle] = useState("")
  const [adressColonia, setAdressColonia] = useState("")
  const [adressEstado, setAdressEstado] = useState("")

  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false) // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false) // 2










  function fechaString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }
  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true)
  }

  const resetForm = () => {
    //campos
    setName("")
    setCua("")
    setUsername("")
    setPassword("")
    setPhone("")
    setEmail("")
    setDate("")
    setAdressCalle("")
    setAdressColonia("")
    setAdressEstado("")

    //condicionales para funcionamiento de enviar formulario
    setEnviarFormulario(false) // 1
    setEnviandoFormulario(false) // 2
  }













  //useEffects
  useEffect(() => {
    if (
      name !== "" &&
      username !== "" &&
      password !== "" &&
      phone !== "" &&
      email !== "" &&
      date !== ""
    ) {
      setEnviarFormulario(true)
    } else {
      setEnviarFormulario(false)
    }
  }, [
    name,
    cua,
    username,
    password,
    phone,
    email,
    date,
    adressCalle,
    adressColonia,
    adressEstado,
  ])











  useEffect(() => {
    if (enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })

      const DatosDelAsesor = {
        "uuid": `${uuid()}`,
        "cua": cua,
        "nombre": name,
        "usuario_crm": username,
        "clave_crm": password,
        "telefono": phone,
        "correo": email,
        "nacimiento": fechaString(date),
        "address_calle": adressCalle,
        "address_colonia": adressColonia,
        "address_estado": adressEstado,
      }


      MiembrosDB
        .append("Asesores", [DatosDelAsesor], {
          authentication: {
            username: process.env.REACT_APP_MIEMBROS_USERNAME,
            password: process.env.REACT_APP_MIEMBROS_PASSWORD
          }
        })
        .then(res => {
          loadingSwal.close()
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Enviar otro',
            showCloseButton: false,
            iconColor: "#0F70D4",
          }).then(() => {
            resetForm()
          });
        })
        .catch(error => {
          console.error(error)
          setEnviandoFormulario(false)
        });



    }
  }, [enviandoformulario])






  return (<>
    <AppPageTemplate title={"Nuevo Asesor"}>

      <h2 className="h2">Nombre completo</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div style={{ height: "30px" }} />


      <h2 className="h2">CUA (Opcional)</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"number"}
        pattern="[0-9]*"
        value={cua}
        onChange={e => setCua((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{ height: "30px" }} />






      <h2 className="h2">Usuario para el CRM</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <div style={{ height: "30px" }} />








      <h2 className="h2">Clave para el CRM</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <div style={{ height: "30px" }} />











      <h2 className="h2">Telefono</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"number"}
        pattern="[+]?[0-9]*"
        value={phone}
        onChange={e => setPhone((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{ height: "30px" }} />









      <h2 className="h2">Correo electrónico</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <div style={{ height: "30px" }} />







      <h2 className="h2">Fecha de nacimiento</h2>
      <div style={{ height: "10px" }} />
      <InputCalendar
        value={date}
        onChange={e => setDate(e.value)}
      />
      <div style={{ height: "30px" }} />









      <h2 className="h2">Dirección</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        placeholder={"Calle"}
        value={adressCalle}
        onChange={e => setAdressCalle(e.target.value)}
      />
      <div style={{ height: "20px" }} />
      <Input
        type={"text"}
        placeholder={"Colonia"}
        value={adressColonia}
        onChange={e => setAdressColonia(e.target.value)}
      />
      <div style={{ height: "20px" }} />
      <Input
        type={"text"}
        placeholder={"Estado"}
        value={adressEstado}
        onChange={e => setAdressEstado(e.target.value)}
      />
      <div style={{ height: "30px" }} />





      <div style={{ height: "10px" }} />
    </AppPageTemplate>

    {
      enviarformulario ?
        <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}><i className="pi pi-send"></i></button>
        :
        <button className="PiButton PiButtonDisabled"><i className="pi pi-send"></i></button>
    }
  </>);
}