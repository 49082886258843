import { useEffect, useState } from "react"

// pop up api
import Swal from 'sweetalert2';

// components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";

// db connection
import SteinStore from "stein-js-client";



export default function ({ stepCounter, setAbleNextStep, setStepCounter, asesorData, setAsesorData }) {
  // form fields
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [keyword, setKeyword] = useState("")


  // DB auth connection
  const MiembrosDB = new SteinStore(process.env.REACT_APP_MIEMBROS_API);






  // Check every field is filled to able sending
  useEffect(() => {
    if (username && password && keyword) {
      setAbleNextStep(true)
    } else {
      setAbleNextStep(false)
    }
  }, [username, password, keyword])





  useEffect(() => {
    if (stepCounter == 1) {
      setStepCounter(0) // Regresamos al estado 0 para hacer posible reenviar el formulario de login si hubo un error




      const loadingSwal = Swal.fire({ // Pop up de cargando
        title: 'Cargando...',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })






      MiembrosDB // Consultamos DB donde tenemos todos los asesores
        .read("Gerentes", {
          authentication: {
            username: process.env.REACT_APP_MIEMBROS_USERNAME, 
            password: process.env.REACT_APP_MIEMBROS_PASSWORD
          }
        })
        .then(data => {
          // Revisamos 1 por 1 a ver si coincide el usuario y contraseña con los que tenemos en la BD
          let asesoresDB = data;
          let matchIdx = null
          asesoresDB.forEach((asesor, idx) => {
            if (username == asesor?.usuario_crm && password == asesor?.clave_crm && process.env.REACT_APP_KEYWORD.toLowerCase() == keyword.toLowerCase()) {
              matchIdx = idx;
            }
          })








          // solo SI encontramos una coincidencia, cerramos el pop up de cargando y obtenemos los datos del asesor en nuestros estados 
          if (matchIdx !== null) {
            loadingSwal.close()

            // En este caso no necesito los datos del asesor, solamente dejaré que avance a registrar el nuevo asesor
            // setBrokerData({
            //   brokerID: asesoresDB[matchIdx].ID,
            //   brokerName: asesoresDB[matchIdx].Nombre,
            // })

            setStepCounter(2)









          } else {
            //SUCCESSSS LOG NOT AUTHORIZED
            setStepCounter(0)
            loadingSwal.close()
            Swal.fire({
              title: 'No autorizado',
              icon: 'error',
              showConfirmButton: false,
              showCloseButton: false,
              iconColor: "#ff4545",
              timer: 2500
            }).then(() => {
              setStepCounter(0)
            })
          }
        })








        .catch(e => {
          setStepCounter(0)

          //SWAAAAAL DE ERROOOOOOOOOOR
          loadingSwal.close()
          Swal.fire({
            title: 'Ocurrió un error',
            icon: 'error',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#ff4545",
            timer: 2500
          }).then(() => {
            setStepCounter(0)
          })
        });
    }
  }, [stepCounter])





  return (
    <AppPageTemplate title={"¡Bienvenido Afiliado!"}>
      <div >
        <h3 className="h3">Nombre de usuario</h3>
        <div style={{ height: "10px" }} />

        <Input
          placeholder={""}
          value={username}
          onChange={e => setUsername(e.target.value)}
        />

        <div style={{ height: "30px" }} />





        <h3 className="h3">Clave del usuario</h3>
        <div style={{ height: "10px" }} />

        <Input
          placeholder={""}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <div style={{ height: "30px" }} />





        <h3 className="h3">Palabra Clave</h3>
        <div style={{ height: "10px" }} />

        <Input
          placeholder={""}
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
        />
      </div>
    </AppPageTemplate>
  )
}




